import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetAllRetaionData,
  HandleFamily,
  HandleNumber,
  HandlePostBasic,
  HandlePostGeust,
  HandlePostUserRelation,
} from "../utils/Api";
import {
  setBasicData,
  setConsultData,
  setLoading,
  setTempData,
  setUserData,
} from "../Redux/Actions/Actions";
import Popup from "../components/Popup";
import { toast } from "react-toastify";

export default function NameDetailsPage() {
  const UserData = useSelector((state) => state.User);
  const Basic = useSelector((state) => state.Basic);
  const MainUser = useSelector((state) => state.MainUser);
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const Dispatch = useDispatch();

  const [relations, setRelations] = useState([]);
  const [relation, setRelation] = useState("");
  const [isRelation, setIsRelaion] = useState(false);
  const [open, setOpen] = useState(false);
  const [profiles, setProfiles] = useState([]);

  useLayoutEffect(() => {
    Dispatch(setLoading({loading:false,otp:false}))
    if (Basic.Gender != null) {
      let datas = [
        {
          userID: MainUser.userID,
          type: MainUser.type,
          firstName: MainUser.firstName,
          Gender: MainUser.Gender,
          authToken: MainUser.authToken,
          age: "some",
        },
      ];
      setProfiles(datas);
      HandleFamily(MainUser.userID, MainUser.type).then((d) => {
        if (d.ok) {
          d.json().then((data) => {
            if (data.relations) {
              setProfiles([...datas, ...data.relations]);
            }
          });
        }
      });

      if (Basic.rid != null) {
        setOpen(true);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (UserData.firstName != null) {
      setFirstName(UserData.firstName);
      setLastName(UserData.LastName);
      setEmail(UserData.email);
      if (UserData.Relation != null) {
        if (UserData.Relation != "self" && UserData.Relation != "") {
          setIsRelaion(true);
          setRelation(UserData.Relation);
        }
      }
    }
    GetAllRetaionData().then((res) => {
      res.json().then((d) => {
        if (d.status == true) {
          setRelations(d.data);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (UserData.firstName != null) {
      setFirstName(UserData.firstName);
      setLastName(UserData.LastName);
      setEmail(UserData.email);
      if (UserData.Relation != null) {
        if (UserData.Relation != "self" && UserData.Relation != "") {
          setIsRelaion(true);
          setRelation(UserData.Relation);
        }
      }
    }
  }, [UserData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data;
    data = {
      firstName,
      LastName,
      email,
      Relation: "self",
      ConnectionID: MainUser.userID,
      mobileNumber: MainUser.mobile,
      mobileCode: MainUser.mobileCode,
    };
    let headers = {
      "Content-Type": "application/json",
      device: "Android",
      Accept: "application/json",
      CorrelationId: 1234551516516565,
      AuthToken: Basic.authToken,
      userId: Basic.id,
    };


    if (Basic.rid == null) {
      HandlePostUserRelation(headers, data).then((d) => {
        d.json().then((datas) => {
          let bas = Basic;
          console.log(datas);
          bas.id = datas.userID;
          bas.type = datas.type;
          bas.authToken = datas.authToken;
          bas.rid = datas.userID;
          Dispatch(setBasicData(bas));
          Dispatch(setUserData({ ...UserData, ...data }));
          navigate("/other-details");
        });
      });
    } else {
      HandlePostBasic(headers, data).then((res) => {
        res.json().then(d=>{
          console.log(d);
          if (Basic.type == null) {
            let bas = Basic;
            bas.type = d.type;
            Dispatch(setBasicData(bas));
          }
          Dispatch(setUserData({ ...UserData, ...data }));
          navigate("/other-details");
        })
       
      });
    }
  };

  const onClose = (data) => {
    console.log(data);
    setOpen(false);
    if (data == null) {
      let bas = Basic;
      bas.rid = null;
      Dispatch(setBasicData(bas));
      setIsRelaion(true);
      setEmail("");
      setFirstName("");
      setLastName("");
      setRelation("");
    } else {
      if (data.Gender == null) {
        let bas = Basic;
        bas.isExisted = false;
        bas.name = data.firstName;
        bas.type = data.type;
        bas.authToken = data.authToken;
        bas.rid = data.userID;
        bas.id = data.userID;
        bas.qs = "";
        Dispatch(setBasicData(bas));
        Dispatch(setTempData({ null: "null" }));
        Dispatch(setConsultData({ null: "null" }));
        Dispatch(setUserData(data));
        setOpen(false);
      } else {
        let bas = Basic;
        bas.isExisted = true;
        bas.id = data.userID;
        bas.name = data.firstName;
        bas.type = data.type;
        bas.authToken = data.authToken;
        bas.rid = null;
        bas.qs = "";
        Dispatch(setBasicData(bas));

        Dispatch(setTempData({ null: "null" }));
        Dispatch(setConsultData({ null: "null" }));
        Dispatch(setUserData({ null: "null" }));
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "LOGIN_SUCESS",//"LOGIN FAILED"
            data:bas
          })
        );
      }
    }
  };

  return (
    <div className="flex justify-center items-center text-thtext bg-gray-100">
      <Popup isOpen={open} onClose={onClose} data={profiles} />
      <div className="min-h-screen container items-center ">
        <div className="h-screen flex flex-col justify-start items-center ">
          <div className=" max-w-md w-full h-44 bg-detailsbg">
            <img className="w-full h-full pt-5" src="./assets/Details.svg" />
          </div>
          <div className="px-8 pt-6 pb-8 max-w-md w-full h-full font-bold">
            <div className="flex justify-start">
              <h2 className=" text-lg text-center mb-6">
                Enter your child's details
              </h2>
            </div>
            <form
              className="flex flex-col justify-between h-3/4"
              onSubmit={handleSubmit}
            >
              <div className="grid grid-cols-2 gap-2">
                <div className=" py-2">
                  <h4 className=" text-sm">First Name</h4>
                  <input
                    className="pt-2 focus:outline-none w-full bg-transparent border-b border-gray-300"
                    type="text"
                    //   placeholder="First Name"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className=" py-2">
                  <h4 className=" text-sm">Last Name</h4>
                  <input
                    className="pt-2 focus:outline-none w-full bg-transparent border-b border-gray-300"
                    type="text"
                    //   placeholder="Last Name"
                    required
                    value={LastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="py-2 col-span-2">
                  <h4 className=" text-sm">Email</h4>
                  <input
                    className="pt-2 focus:outline-none w-full bg-transparent border-b border-gray-300"
                    type="email"
                    // placeholder="Email"
                    value={email}
                    // required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <button
                className={`bg-primary h-12 text-white font-bold py-2 px-4 mt-6 rounded-full w-full`}
                type="submit"
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
